import { t } from "i18next";
import React, { useState, useEffect } from "react";
import CountryList from "react-select-country-list";

const CountryDropdown = ({ selectedCountry = "GB", onCountryChange }) => {
  const [value, setValue] = useState(selectedCountry);
  const options = CountryList().getData();
  console.log("Selected country:", value);
  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onCountryChange) onCountryChange(newValue);
  };
  useEffect(() => {
    setValue(selectedCountry); // Sync  state with props //
  }, [selectedCountry]);

  return (
    <>
      <select
        id="country-select"
      
        value={value}
        onChange={handleChange}
        style={{ width: "100%" }}
      >
        <option value="">{t("enter_country")}</option>
        {options.map((country) => (
          <option key={country.value} value={country.value}>
            {country.label}
          </option>
        ))}
      </select>
    </>
  );
};
export default CountryDropdown;
